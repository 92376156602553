.ScrollBar {
  /* 进度条全局样式 */
  & {
    overflow: hidden;
    overflow-y: auto;
  }
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /* 高宽分别对应横竖滚动条的尺寸 */
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: .5rem;
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: var(--border_color);
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    background: var(--bg_color);
  }
}

.darkTheme {
  --color: rgba(255, 255, 255, 0.65);
  --color_active: #1890ff;  // active 时字的颜色
  --color_hover: red;  // hover 时字的颜色
  --color_hover_green: green;  // hover 时字的颜色
  --small_color: #ccc;  // 小字的颜色
  --bg_color: #001529;  // 背景色
  --bg_color_hover: rgb(51, 61, 69);  // hover 时出现的背景色
  --bg_color_mask: rgba(230, 247, 255, 0.18039215686);  // 遮罩层背景色
  --border_color: white;
  --keyword_highlight: yellow;  // 关键字被高亮时的颜色
}

.lightTheme {
  --color: #001529;
  --color_active: #1890ff;
  --color_hover: red;
  --color_hover_green: green;  // hover 时字的颜色
  --small_color: #ccc;
  --bg_color: rgba(255, 255, 255);
  --bg_color_hover: #abbac5;
  --bg_color_mask: rgba(230, 247, 255, 0.6901960784313725);
  --border_color: #001529;
  --keyword_highlight: orange;
}
