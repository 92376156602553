$headerHight: 3rem;
$adminHeaderHight: 1.8rem;
.routerWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.RouterHead {
    height: $headerHight;
}

.RouterView {
    width: 100%;
    height: calc(100% - #{$headerHight});
    font-size: 0;
}

.AdminRouterHead {
    height: $adminHeaderHight;
}

.AdminRouterView {
    width: 100%;
    height: calc(100% - #{$adminHeaderHight});
    font-size: 0;
}
