.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: var(--bg_color_mask);
  >img {
    width: 100%;
    height: 100%;
  }
}
